import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { HttpClientService } from '../../core/services/http/http-client.service';
import { CCTransaction } from '../../history/billing-history/models/credit-card-transaction.model';
import { CreditCard } from '../models/credit-card.model';

@Injectable()
export class CreditCardsService {

    public constructor(private httpClientService: HttpClientService,
                       private readonly httpClient: HttpClient,
                       private apiEndpointService: ApiEndpointService,
    ) {
    }

    /**
     * get Credit Cards
     *
     */
    public getCreditCards() {
        const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('getCreditCards')).toString();
        return this.httpClientService.get<any>(endpoint);
    }

    public changePrimaryCreditCard(data) {
        const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('changePrimaryCreditCard')).toString();
        return this.httpClientService.put<any>(endpoint, data);
    }

    public removeCreditCard(usercreditcardid: string) {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('removeCreditCard'))
            .param('usercreditcardid', usercreditcardid)
            .toString();
        return this.httpClientService.delete<any>(endpoint);
    }

    public getPrimaryCreditCard(): Observable<CreditCard> {
        const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('getPrimaryCreditCard')).toString();
        return this.httpClientService.get<CreditCard>(endpoint);
    }

    public chargeCreditCard(ccTransaction: CCTransaction): Observable<string> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('chargeCreditCard'))
            .toString();
        return this.httpClient.post(endpoint, ccTransaction, { responseType: 'text' });
    }
}
