/* src/app/history/recent-shipment/recent-shipment.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.recent-shipments,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.sub-header,
.recent-shipments .ups-page-header,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.ups-page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.ups-page-header .page-actions,
.ups-page-header [page-actions] {
  padding: 0;
}
.refresh .text {
  display: flex;
}
@media only screen and (max-width: 23.438rem) {
  .refresh .text {
    display: none;
  }
}
.refresh .icon {
  cursor: pointer;
  border: 0 !important;
  display: none;
}
@media only screen and (max-width: 23.438rem) {
  .refresh .icon {
    display: block;
  }
}
.recent-shipments {
  background-color: white;
  min-height: 100%;
}
.recent-shipments .off-hours-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.6875rem 2.75rem 0.6875rem 1.3125rem;
  font-size: 0.875rem;
  line-height: 1.36;
  color: rgba(36, 36, 36, 0.87);
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(211, 211, 211, 0.5);
  background-color: #fffbdb;
  border: solid 1px #fff6a9;
  border-left: solid 4px #fff59d;
}
.recent-shipments .off-hours-message .material-icons {
  width: 20px;
  height: 20px;
  font-size: 20px;
  margin-right: 1rem;
}
.recent-shipments .off-hours-message .message {
  flex: 1;
}
.recent-shipments .status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.recent-shipments .status.declined .material-icons {
  color: #d50000;
}
.recent-shipments .status .material-icons {
  font-size: 1rem;
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem;
}
.recent-shipments .table-wrapper {
  position: relative;
}
.sub-header {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1em;
}
@media screen and (min-width: 48rem) {
  .sub-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5em;
    margin-top: -3em;
  }
}
.sub-header .notice {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.sub-header .notice .mat-icon {
  margin-right: 0.25em;
}
::ng-deep .status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
::ng-deep .status::before {
  content: "";
  background-color: transparent;
  border: solid thin transparent;
  border-radius: 50%;
  display: block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}
::ng-deep .status.pending::before {
  background-color: #004791;
}
::ng-deep .status.printed::before,
::ng-deep .status.in-transit::before,
::ng-deep .status.delivered::before {
  background-color: #64a70b;
}
::ng-deep .status.manifest::before,
::ng-deep .status.pickup-scan::before,
::ng-deep .status.bir::before {
  background-color: #e1c8b0;
}
::ng-deep .status.voided::before,
::ng-deep .status.exception::before {
  background-color: #DF2901;
}
::ng-deep .status.reinstated::before {
  background-color: #404040;
}
/*# sourceMappingURL=recent-shipment.component.css.map */
