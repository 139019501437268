/* src/app/history/package-investigation/investigation-form/investigation-form.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.investigation-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.investigation-info .mat-mdc-form-field {
  flex: 1;
}
@media screen and (min-width: 48rem) {
  .investigation-info .mat-mdc-form-field {
    flex: 0 45%;
  }
  .investigation-info .mat-mdc-form-field:nth-of-type(odd) {
    padding-right: 2em;
  }
  .investigation-info .mat-mdc-form-field:nth-of-type(even) {
    padding-left: 2em;
  }
}
.others {
  display: flex;
}
.others .mat-form-field-infix {
  border: 1px solid #d9d9d6;
}
/*# sourceMappingURL=investigation-form.component.css.map */
