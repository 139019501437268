/* src/app/history/package-investigation/package-investigation.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.claims {
  min-width: 23.438rem;
  max-width: 75rem;
  margin: 0 auto;
}
.claims .claim-sections {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media screen and (min-width: 48rem) {
  .claims .claim-sections .claim-instructions {
    flex: 1;
  }
}
.claims ul.steps {
  padding: 0;
  margin: 0;
  list-style-type: none;
  counter-reset: instructions-counter;
}
.claims ul.steps li.step {
  margin-bottom: 2em;
}
.claims .additional {
  background-color: #f4f4f4;
  color: #595555;
  padding: 2em 0em;
  margin-bottom: 2em;
}
.claims .additional p {
  padding: 0em 2.5em;
}
.claims .form-actions {
  justify-content: flex-start;
}
/*# sourceMappingURL=package-investigation.component.css.map */
