/* src/app/history/report-history/report-shipment-eu-dialog/report-shipment-eu-dialog.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.report-shipment-eu-dialog .select-wrapper {
  position: relative;
}
.report-shipment-eu-dialog .shipment-sections {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
@media screen and (min-width: 48rem) {
  .report-shipment-eu-dialog .shipment-sections {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.report-shipment-eu-dialog .shipment-sections .terms {
  margin-top: 2rem;
}
@media screen and (min-width: 48rem) {
  .report-shipment-eu-dialog .shipment-section.carrier-information,
  .report-shipment-eu-dialog .shipment-section.tracking-information {
    width: 50%;
  }
  .report-shipment-eu-dialog .shipment-section.ship-to-information {
    width: 100%;
  }
  .report-shipment-eu-dialog .shipment-section.ship-to-information .section-content {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .report-shipment-eu-dialog .shipment-section.ship-to-information .section-content > * {
    width: 50%;
  }
  .report-shipment-eu-dialog .shipment-section.ship-to-information .section-content > *:nth-child(odd) {
    padding-right: 2rem;
  }
  .report-shipment-eu-dialog .shipment-section.ship-to-information .section-content > *:nth-child(even) {
    padding-left: 2rem;
  }
  .report-shipment-eu-dialog .shipment-section:nth-child(1) {
    padding-right: 2rem;
  }
  .report-shipment-eu-dialog .shipment-section:nth-child(2) {
    padding-left: 2rem;
  }
}
.report-shipment-eu-dialog .shipment-section .section-title {
  color: #004791;
  font-size: 1.35em;
  font-weight: 700;
  margin-bottom: 1em;
}
.report-shipment-eu-dialog .shipment-section .section-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
/*# sourceMappingURL=report-shipment-eu-dialog.component.css.map */
