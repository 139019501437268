<div class="charge-credit-card ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{ PaymentSuccessful ? 'PaymentReceipt' : 'payInvoice' | translate }}</h2>
        <mat-dialog-actions>
            <button (click)="printReceipt($event)"
                    *ngIf="PaymentSuccessful"
                    class="form-action"
                    mat-raised-button
                    type="button">
                {{'Print'|translate}}
            </button>
        </mat-dialog-actions>
        <button class="icon-button close-button" mat-dialog-close="{{ PaymentSuccessful }}" mat-raised-button type="button">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
    </mat-dialog-content>
</div>

<ng-template #form>
    <ng-container *ngIf="!PaymentSuccessful" [ngTemplateOutlet]="unpaid"></ng-container>
    <ng-container *ngIf="PaymentSuccessful" [ngTemplateOutlet]="paid"></ng-container>
</ng-template>

<ng-template #unpaid>
    <form [formGroup]="formGroup" class="ups-form">
        <div class="form-body">
            <div class="content-items">
                <h4 class="content-items-title">{{'invoiceInformation'|translate}}</h4>

                <div class="content-item">
                    <div class="key">{{'InvoiceNo'|translate}}</div>
                    <div class="value">{{CCData?.InvoiceID}}</div>
                </div>
                <div class="content-item">
                    <div class="key">{{'invoiceTotal'|translate}}</div>
                    <div class="value">{{CCData?.Balance | number : '1.2-2' }} USD</div>
                </div>
            </div>

            <div class="content-items">
                <h4 class="content-items-title">{{'creditCardInformation'|translate}}</h4>

                <div class="content-item">
                    <div class="key">{{'primaryCard'|translate}}</div>
                    <div class="value">{{PrimaryCard?.CreditCardType}} {{PrimaryCard?.Last4}}</div>
                </div>
                <!-- <div class="content-item">
                    <div class="key">{{'expiration'|translate}}</div>
                    <div class="value">{{PrimaryCard?.ExpDateMonth}}/{{PrimaryCard?.ExpDateYear}}</div>
                </div> -->
            </div>

            <mat-form-field class="ccv">
                <mat-label>{{'CCV'|translate}}</mat-label>
                <input formControlName="ccv"
                       matInput
                       maxlength="4"
                       minlength="3"
                       onfocus="this.select();"
                       pattern="^[0-9]{3,4}$"
                       required
                       type="text">
                <mat-error>
                    {{'enterCCV'|translate}}
                </mat-error>
            </mat-form-field>

            <div class="form-actions">
                <ng-container *spinOn="isPaymentInProgress">
                    <button (click)="throttleActionService.execute(submitPayment.bind(this))"
                            *ngIf="!PaymentSuccessful"
                            [disabled]="!formGroup.valid"
                            class="form-action"
                            mat-raised-button
                            type="button">
                        {{ 'PayNow'|translate }}
                    </button>
                </ng-container>
            </div>

            <div class="note">
                <mat-icon>info_outline</mat-icon>
                <span>{{'defaultCardChangeInfo'|translate}}</span>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #paid>
    <div id="billing-receipt">
        <div class="content-items">
            <div class="content-items-title">{{'OrderInformation'|translate}}</div>

            <div class="content-item">
                <div class="key">{{'CustomerId'|translate}}</div>
                <div class="value">{{CustomerID}}</div>
            </div>
            <div class="content-item">
                <div class="key">{{'InvoiceNo'|translate}}</div>
                <div class="value">{{CCData?.InvoiceID}}</div>
            </div>
        </div>

        <div class="content-items">
            <div class="content-items-title">{{'onlinePaymentDesc'|translate}}</div>

            <div class="content-item">
                <div class="key">{{'Total'|translate}}</div>
                <div class="value">{{CCData?.TotalAmount}}</div>
            </div>
            <div class="content-item">
                <div class="key">{{'RemainingBalance'|translate}}</div>
                <div class="value">$0.00</div>
            </div>
        </div>

        <div class="content-items">
            <div class="content-items-title">{{'PaymentInformation'|translate}}</div>

            <div class="content-item">
                <div class="key">{{'Merchant'|translate}}</div>
                <div class="value">Parcel Pro Inc.</div>
            </div>
            <div class="content-item">
                <div class="key">{{'PaymentAmount'|translate}}</div>
                <div class="value">{{CCData?.TotalAmount}}</div>
            </div>
            <div class="content-item">
                <div class="key">{{'CardNo'|translate}}</div>
                <div class="value">{{PrimaryCard?.Last4}}</div>
            </div>
            <div class="content-item">
                <div class="key">{{'CardType'|translate}}</div>
                <div class="value">{{PrimaryCard?.CreditCardType}}</div>
            </div>
            <div class="content-item">
                <div class="key">{{'AuthorizationNo'|translate}}</div>
                <div class="value">{{AuthNo}}</div>
            </div>
            <div class="content-item">
                <div class="key">{{'PaymentMethod'|translate}}</div>
                <div class="value">{{'onlineCreditCard'|translate}}</div>
            </div>
        </div>
    </div>
</ng-template>
